<template>
  <v-item-group>
    <v-container class="text-center">
      <v-row>
        <v-col cols="6" class="pa-1">
          <v-item v-slot="{ active, toggle }">
            <div @click="toggle">
              <v-card rounded="xl" :color="active ? '#eceff1' : ''" active-class="active-card" class="pa-7" @click="sendData('9:00 - 14:00')">
                <p class="font-weight-bold">9:00 - 14:00</p>
              </v-card>
            </div>
          </v-item>
        </v-col>
        <v-col cols="6" class="pa-1">
          <v-item v-slot="{ active, toggle }">
            <div @click="toggle">
              <v-card rounded="xl" :color="active ? '#eceff1' : ''" class="pa-7" @click="sendData('14:00 - 19:00')">
                <p class="font-weight-bold">14:00 - 19:00</p>
              </v-card>
            </div>
          </v-item>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pa-1">
          <v-item v-slot="{ active, toggle }">
            <div @click="toggle">
              <v-card rounded="xl" :color="active ? '#eceff1' : ''" class="pa-7" @click="sendData('指定なし')">
                <p class="font-weight-bold">指定なし</p>
              </v-card>
            </div>
          </v-item>
        </v-col>
      </v-row>
    </v-container>
  </v-item-group>
</template>

<script>
import dayjs from 'dayjs'

export default {
  model: {
    prop: 'requestTimeProp',
    event: 'inputField'
  },
  props: {
    requestTimeProp: {
      type: String,
      default: ''
    },
    requestDay: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      valid: true,
      requestTime: this.requestTimeProp,
      requestTimes: ['指定なし', '9:00 - 14:00', '14:00 - 19:00']
    }
  },
  methods: {
    sendData: function (e) {
      this.$emit('inputField', e)
    }
  },
  watch: {
    requestTimeProp: {
      handler: function (newValue) {
        this.requestTime = newValue
      }
    },
    requestDay: {
      handler: function (newValue) {
        // 今日
        const today = dayjs().format('YYYY-MM-DD')
        // newValueが今日だったら
        if (newValue === today) {
          this.requestTimes = ['指定なし', '14:00 - 19:00']
        } else {
          this.requestTimes = ['指定なし', '9:00 - 14:00', '14:00 - 19:00']
        }
      }
    }
  }
}
</script>

<style >
</style>