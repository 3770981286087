<template>
  <div>
    <div id="lp-request" class="d-flex justify-center pt-9">
      <RequestLabel>
        申し込み
      </RequestLabel>
    </div>
    <RequestRegistrationStepper />
  </div>
</template>

<script>

import RequestRegistrationStepper from '@/components/component/landing/RequestRegistrationStepper'
import RequestLabel from '@/components/basic/landing/RequestLabel'

export default {
  components: {
    RequestLabel,
    RequestRegistrationStepper
  }
}
</script>