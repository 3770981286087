var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-item-group',[_c('v-container',{staticClass:"text-center"},[_c('v-row',[_c('v-col',{staticClass:"pa-1",attrs:{"cols":"6"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('div',{on:{"click":toggle}},[_c('v-card',{staticClass:"pa-7",attrs:{"rounded":"xl","color":active ? '#eceff1' : '',"height":"100"},on:{"click":function($event){return _vm.sendData('玄関フード')}}},[_c('p',{staticClass:"font-weight-bold"},[_vm._v("玄関フード")])])],1)]}}])})],1),_c('v-col',{staticClass:"pa-1",attrs:{"cols":"6"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('div',{on:{"click":toggle}},[_c('v-card',{staticClass:"pa-7",attrs:{"rounded":"xl","color":active ? '#eceff1' : '',"height":"100"},on:{"click":function($event){return _vm.sendData('物置')}}},[_c('p',{staticClass:"font-weight-bold"},[_vm._v("物置")])])],1)]}}])})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pa-1",attrs:{"cols":"6"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('div',{on:{"click":toggle}},[_c('v-card',{staticClass:"pa-7",attrs:{"rounded":"xl","color":active ? '#eceff1' : '',"height":"100"},on:{"click":function($event){return _vm.sendData('車庫')}}},[_c('p',{staticClass:"font-weight-bold"},[_vm._v("車庫")])])],1)]}}])})],1),_c('v-col',{staticClass:"pa-1",attrs:{"cols":"6"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('div',{on:{"click":toggle}},[_c('v-card',{staticClass:"pa-7",attrs:{"rounded":"xl","color":active ? '#eceff1' : '',"height":"100"},on:{"click":function($event){return _vm.sendData('オートロック付きマンション')}}},[_c('p',{staticClass:"font-weight-bold"},[_vm._v("オートロック付きマンション")])])],1)]}}])})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pa-1",attrs:{"cols":"12"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('div',{on:{"click":toggle}},[_c('v-card',{staticClass:"pa-7",attrs:{"rounded":"xl","color":active ? '#eceff1' : '',"height":"100"},on:{"click":function($event){return _vm.sendData('宅配ボックス')}}},[_c('p',{staticClass:"font-weight-bold"},[_vm._v("宅配ボックス")])])],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }