<template>
  <div class="appeal__content">
    <v-container class="py-4 py-lg-8">
      <v-row>
        <v-col class="d-flex justify-center">
          <SectionLabel>
            メリット
          </SectionLabel>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-for="(item, i) in features"
          :key="i"
          cols="6"
          sm="4"
          lg="4"
        >
            <v-responsive max-width="300" class="mx-auto text-center">
              <div class="text-h6 mt-3"><span class="pa-1 primary--text font-weight-bold appeal__h6">{{ item.title }}</span></div>
              <div class="font-weight-regular mt-2">{{ item.description }}</div>
            </v-responsive>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SectionLabel from '@/components/basic/landing/SectionLabel'

export default {
  components: {
    SectionLabel
  },
  data() {
    return {
      features: [{
        icon: 'mdi-account-check-outline',
        title: 'オンラインで申し込み',
        description: 'Webサイトから集荷申し込みを行うことができます。希望する日付を指定することであなたの予定に合った日に集荷します。'
      }, {
        icon: 'mdi-lifebuoy',
        title: 'オンライン決済対応',
        description: 'クレジットカード、PayPay、LINEPay、キャリア決済(Docomo、au、Softbank)に対応しているので、お金のやり取りを会うことなく行うことができます。'
      }, {
        icon: 'mdi-email-open-multiple-outline',
        title: '会わずに集荷',
        description: 'ドアノブにかけたり宅配ボックスに入れておけば、指定日にスタッフが集荷に伺います。会わずにクリーニングの受け渡しができるので感染症対策になります。もちろん在宅中でもご利用いただけます。'
      }, {
        icon: 'mdi-clock-outline',
        title: 'お店にいかない',
        description: '大切な休日にクリーニング店に行くことなく、平日の朝出勤前にクリーニングを出すことができます。'
      }, {
        icon: 'mdi-twitter',
        title: '家事の時短',
        description: '面倒くさい洗濯を、専門の業者である私達にまかせて家族の時間や趣味の時間を増やしましょう。'
      }, {
        icon: 'mdi-chart-box-outline',
        title: '選べる集荷場所',
        description: '集荷場所はドアノブ、宅配ボックス、車庫、玄関フードから選ぶことができます。自宅に合わせた集荷場所を選ぶことができます。'
      }]
    }
  }
}
</script>

<style lang="scss">
.appeal {
  &__content {
    background-color: #ccecfb;
  }
  &__h6 {
    background-color: white;
  }
}
</style>
