<template>
  <div>
    <div v-if="!check">
      <p>郵便番号を入力してください</p>
      <v-otp-input
        type="number"
        inputmode="numeric"
        length="7"
        :value='postalCode'
        @change='sendData'
      ></v-otp-input>
    </div>
    <v-text-field
      v-if="check"
      type="number"
      inputmode="numeric"
      :counter="7"
      :rules="postalCodeRules"
      label="郵便番号"
      :value='postalCode'
      @change='sendData'
    ></v-text-field>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/firestore'

export default {
  props: {
    postalCode: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      postalCodeWhitelist: [],
      postalCodeRules: [
        v => !!v || '郵便番号は必須入力です。対応可能地域の郵便番号を入力して下さい。',
        v => (v && v.length === 7) || '郵便番号は7文字で入力してください。'
      ]
    }
  },
  created: function () {
    // 集荷可能な郵便番号を取得
    firebase.firestore()
      .collection('postalCodeWhiteList')
      .doc('JWZVIiohJXgFcnprGEee')
      .get()
      .then(doc => {
        const strwhitelist = doc.data().postalCode
        this.postalCodeWhitelist = strwhitelist.split(',')
      }).catch(error => {
        console.error(error)
      })
  },
  computed: {
    check () {
      const postalCode = this.postalCode
      let returnValue
      if (postalCode.length === 7) {
        returnValue = true
      } else {
        returnValue = false
      }
      return returnValue
    }
  },
  methods: {
    sendData: function (e) {
      this.$emit('update:postalCode', e)
    }
  }
}
</script>
