<template>
  <div>
    <v-text-field
      :append-icon="show ? mdiEyeSvgPath : mdiEyeOffSvgPath"
      :type="show ? 'text' : 'password'"
      v-model="password"
      :value="password"
      @click:append="show = !show"
      required
      label='パスワード'
      :rules="passwordRules"
      @input='sendData'
    ></v-text-field>
  </div>
</template>

<script>
import { mdiEye, mdiEyeOff } from '@mdi/js'
export default {
  model: {
    prop: 'passwordProp',
    event: 'inputField'
  },
  props: {
    passwordProp: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      password: this.passwordProp,
      show: false,
      mdiEyeSvgPath: mdiEye,
      mdiEyeOffSvgPath: mdiEyeOff,
      passwordRules: [
        v => !!v || 'パスワードを入力してください。',
        v => (v && v.length >= 6) || 'パスワードは６文字以上で入力してください。'
      ]
    }
  },
  methods: {
    sendData: function (e) {
      this.$emit('inputField', e)
    }
  },
  watch: {
    passwordProp: {
      handler: function (newValue) {
        this.password = newValue
      }
    }
  }
}
</script>
