<template>
  <v-item-group>
    <v-container class="text-center">
      <v-row>
        <v-col cols="6" class="pa-1">
          <v-item v-slot="{ active, toggle }">
            <div @click="toggle">
              <v-card rounded="xl" :color="active ? '#eceff1' : ''" height="100"  class="pa-7" @click="sendData('玄関フード')">
                <p class="font-weight-bold">玄関フード</p>
              </v-card>
            </div>
          </v-item>
        </v-col>
        <v-col cols="6" class="pa-1">
          <v-item v-slot="{ active, toggle }">
            <div @click="toggle">
              <v-card rounded="xl" :color="active ? '#eceff1' : ''" height="100"  class="pa-7" @click="sendData('物置')">
                <p class="font-weight-bold">物置</p>
              </v-card>
            </div>
          </v-item>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" class="pa-1">
          <v-item v-slot="{ active, toggle }">
            <div @click="toggle">
              <v-card rounded="xl" :color="active ? '#eceff1' : ''" height="100"  class="pa-7" @click="sendData('車庫')">
                <p class="font-weight-bold">車庫</p>
              </v-card>
            </div>
          </v-item>
        </v-col>
        <v-col cols="6" class="pa-1">
          <v-item v-slot="{ active, toggle }">
            <div @click="toggle">
              <v-card rounded="xl" :color="active ? '#eceff1' : ''" height="100"  class="pa-7" @click="sendData('オートロック付きマンション')">
                <p class="font-weight-bold">オートロック付きマンション</p>
              </v-card>
            </div>
          </v-item>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pa-1">
          <v-item v-slot="{ active, toggle }">
            <div @click="toggle">
              <v-card rounded="xl" :color="active ? '#eceff1' : ''" height="100" class="pa-7" @click="sendData('宅配ボックス')">
                <p class="font-weight-bold">宅配ボックス</p>
              </v-card>
            </div>
          </v-item>
        </v-col>
      </v-row>
    </v-container>
  </v-item-group>
</template>

<script>
export default {
  model: {
    prop: 'baggageSpaceProp',
    event: 'inputField'
  },
  props: {
    baggageSpaceProp: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      baggageSpace: this.baggageSpaceProp,
      items: [
        '玄関フード',
        '物置',
        '車庫',
        '宅配ボックス',
        'オートロック付きマンション'
      ],
      checkbox: false
    }
  },
  methods: {
    sendData: function (e) {
      this.$emit('inputField', e)
    }
  },
  watch: {
    baggageSpaceProp: {
      handler: function (newValue) {
        this.baggageSpace = newValue
      }
    }
  }
}
</script>
