<template>
  <v-app>
    <v-app-bar
      app
      color="white"
      elevation="0"
    >
      <div class="d-flex align-center">
        <v-img
          alt="Mottette Logo"
          class="shrink mr-2"
          contain
          src="https://firebasestorage.googleapis.com/v0/b/non-face-asset/o/landing%2F%E3%83%A2%E3%83%83%E3%83%86%E3%83%83%E3%83%86%E3%83%AD%E3%82%B4_%E6%96%87%E7%84%A1%E3%81%97.png?alt=media&token=b2f92630-9315-4a5e-9400-c74d2f36bd3e"
          transition="scale-transition"
          width="110"
        />
      </div>

      <v-spacer></v-spacer>

      <v-btn href="https://my.mottette.jp/signinpassword" rounded color="primary" large class="white--text my-1 w-full w-sm-auto font-weight-bold ">
        ログイン
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import fbInitializeApp from '@/javascript/FBInitializeApp.js'
import IEReject from '@/javascript/IEReject.js'

export default {
  name: 'App',
  metaInfo: process.env.NODE_ENV === 'production' ? {
    meta: [
      // insighit facebook
      { vmid: 'insighitfacebook', name: 'facebook-domain-verification', content: '5gocyclpojtpg8djadjlo9vw06gwz5' }
    ],
    script: [
      // insighit tag manager
      {
        vmid: 'insighitgtaghead',
        innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-KFDD995');`
      },
      // tag manager
      {
        vmid: 'gtaghead',
        innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-TVTDCHGP');`
      }
    ],
    // insighit tag manager
    noscript: [
      {
        vmid: 'insighitgtagbody',
        innerHTML: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KFDD995"
height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
        pbody: true
      },
      // tag manager
      {
        vmid: 'gtagbody',
        innerHTML: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TVTDCHGP"
height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
        pbody: true
      }
    ],
    __dangerouslyDisableSanitizersByTagID: {
      insighitgtaghead: ['innerHTML'],
      insighitgtagbody: ['innerHTML'],
      gtaghead: ['innerHTML'],
      gtagbody: ['innerHTML']
    }
  } : {
    meta: [
      { vmid: 'robots', name: 'robots', content: 'noindex' }
    ]
  },
  data: () => ({
    //
  }),
  mounted: function () {
    IEReject()
  },
  created: function () {
    fbInitializeApp()
  }
};
</script>
