<template>
  <div class="youtube__content">
    <v-container class="py-4 py-lg-8">
      <v-row>
        <v-col class="d-flex justify-center">
          <SectionLabel>
            TVCM動画
          </SectionLabel>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          class="youtube__col"
        >
          <div class="youtube__frame">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/TCUdcyF8_CY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SectionLabel from '@/components/basic/landing/SectionLabel'

export default {
  components: {
    SectionLabel
  },
  data() {
    return {
      features: [{
        description: 'Yシャツで利用しているシワなくキレイに納品されてよかった。'
      }, {
        description: '​犬の散歩に行ったり、細かく出かけることが多いので置き配は便利だと思います。'
      }, {
        description: '​持病で体調が悪い日もあるので対面応対でないのがありがたいです。'
      }, {
        description: 'コロナ禍で利用したが便利でした。'
      }, {
        description: '​仕事で不在がちなのでとてもありがたいです。'
      }, {
        description: '​回収日時に家で待たなければならないという時間のロスをなくすことができた。'
      }]
    }
  }
}
</script>

<style lang="scss">
.youtube {
  &__content {
    background-color: #ccecfb;
  }
  &__frame {
      position: relative;
  width: 100%;
  padding-top: 56.25%;
    iframe {
        position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
    }
  }
  &__comment {
    position: relative;
    background-color: white;
    z-index: 1;
    padding: 25px;
    margin-bottom: 20px;
    border-radius: 35px;
    &:before {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      z-index: -1;
      border-top: 20px solid white;
      border-right: 20px solid transparent;
      border-left: 20px solid transparent;
    }
  }
}
</style>
