<template>
  <v-fab-transition>
    <v-btn
      href="/#lp-request"
      color="red"
      rounded
      x-large
      class="floating-request-button__content v-btn--example font-weight-bold white--text"
    >
      申し込む
      <v-icon right>mdi-menu-right</v-icon>
    </v-btn>
  </v-fab-transition>
</template>

<script>
  export default {
  }
</script>

<style lang="scss">
.floating-request-button {
    &__content {
      bottom: 0;
      right: 0;
      position: fixed;
      margin: 0 16px 16px 0;
      z-index: 100;
    }
  }
</style>