<template>
  <div>
    <v-text-field
    type="number"
    inputmode="numeric"
    v-model="tel"
    :counter="11"
    :rules="telRules"
    label="電話番号(集荷日の朝にSMS通知します)"
    required
    :value='tel'
    @input='sendData'
    ></v-text-field>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'telProp',
    event: 'inputField'
  },
  props: {
    telProp: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      tel: this.telProp,
      telRules: [
        v => !!v || '電話番号は必須入力です（半角数字のみ入力可）。',
        v => (v && v.length <= 11) || '電話番号は11文字以内で入力してください。'
      ]
    }
  },
  methods: {
    sendData: function (e) {
      this.$emit('inputField', e)
    }
  },
  watch: {
    telProp: {
      handler: function (newValue) {
        this.tel = newValue
      }
    }
  }
}
</script>
