<template>
  <div>
    <v-text-field
    v-model="name"
    :counter="10"
    :rules="nameRules"
    label="名前"
    required
    :value='name'
    @change='sendData'
    ></v-text-field>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'nameProp',
    event: 'inputField'
  },
  props: {
    nameProp: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      valid: true,
      name: this.nameProp,
      nameRules: [
        v => !!v || '名前は必須入力です。',
        v => (v && v.length <= 10) || '名前は10文字以内で入力してください。'
      ]
    }
  },
  methods: {
    sendData: function (e) {
      this.$emit('inputField', e)
    }
  },
  watch: {
    nameProp: {
      handler: function (newValue) {
        this.name = newValue
      }
    }
  }
}
</script>
