<template>
  <div>
      <v-text-field
      v-model="address2"
      :rules="address2Rules"
      :counter="30"
      label="枝番・マンション・アパート名・部屋番号"
      required
      :value='address2'
      @change='sendData'
      ></v-text-field>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'address2Prop',
    event: 'inputField'
  },
  props: {
    address2Prop: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      valid: true,
      address2: this.address2Prop,
      address2Rules: [
        v => !!v || '枝番・マンション・アパート名・部屋番号は必須入力です。',
        v => (v && v.length <= 30) || '枝番・マンション・アパート名・部屋番号は30文字以内で入力してください。'
      ]
    }
  },
  methods: {
    sendData: function (e) {
      this.$emit('inputField', e)
    }
  },
  watch: {
    address2Prop: {
      handler: function (newValue) {
        this.address2 = newValue
      }
    }
  }
}
</script>
