<template>
  <v-sheet class="">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#e3e3e3" fill-opacity="1" d="M0,224L1440,128L1440,320L0,320Z"></path></svg>
    <div class="description__content">
      <v-container class="text-center">
        <div class="description__content mt-6">
          <h3><span class="description__text1 white--text pa-2">自宅で会わずにクリーニングの</span><br><span class="description__text1 white--text pa-2">受け渡しができます。</span></h3>
          <h3 class="mt-5"><span class="description__text2 primary--text">申し込みから支払いまでオンラインで完結</span></h3>
        </div>
      </v-container>
    </div>
  </v-sheet>
</template>

<script>
export default {
  components: {
  }
}
</script>

<style lang="scss">
.description {
  &__content {
    background-color: #e3e3e3 !important;
    position: relative;
    top: -10px
  }
  &__text1 {
    background-color: #00a0e9;
    margin: auto;
    padding: auto;
  }
  &__text2 {
    border-bottom: solid;
    border-color: black;
    border-width: 4;
  }
}
</style>
