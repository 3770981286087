<template>
  <v-sheet>
    <v-container class="py-4 py-lg-8">
      <div class="text-center">
        <SectionLabel>
          ニュース
        </SectionLabel>
      </div>
      <v-expansion-panels
        v-model="panel"
        multiple
        class="mt-2"
      >
        <v-expansion-panel
          v-for="(item, i) in faq"
          :key="i"
        >
          <v-expansion-panel-header class="">
            {{ item.question }}
          </v-expansion-panel-header>
          <v-expansion-panel-content class="">
            <div v-html="item.answer"></div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </v-sheet>
</template>
<script>
import SectionLabel from '@/components/basic/landing/SectionLabel'
export default {
  components: {
    SectionLabel
  },
  data() {
    return {
      panel: [0],
      faq: [{
        question: '2024/8/9：お盆の休業について',
        answer: `8/10（土）～8/18（日）はお盆休業となります。納期が大幅に変更となります。ご了承ください。`
      },{
        question: '2023/12/15：年末年始休業に伴う納期変更について',
        answer: `誠に勝手ながら下記の期間は休業とさせて頂きます。<br>
12/30（土）～1/4（木）<br>
納期が大幅に変更となりますので、ご確認ください。<br><br>
(集)12/19 → (納)12/24 ~ 12/29<br>
(集)12/20 → (納)12/25 ~ 12/29<br>
(集)12/21 → (納)12/26 ~ 12/29<br>
(集)12/22 → (納)12/27 ~   1/5<br>
(集)12/25 → (納)12/29 ~   1/9<br>
(集)12/26 → (納)  1/5 ~  1/10<br>
(集)12/27 → (納)  1/9 ~  1/11<br>
(集)12/28 → (納)  1/9 ~  1/12<br>
(集)12/29 → (納)  1/9 ~  1/15<br>`
      }, {
        question: '2022/7/4：KDDIの通信障害による影響について',
        answer: `平素は格別のご高配を賜り、厚く御礼申し上げます。7月2日より発生しているKDDIの通信障害の影響で、電話が繋がりづらい状況、お客様からインターネットでお申込み頂いた集荷申込のご確認メールが届かないという状況が発生しております。
これにより、お客様への連絡が遅くなってしまったり、弊社への電話が繋がりづらいことが考えられます。お客さまにはご不便、ご迷惑をおかけしてしまい、誠に申し訳ございません。
何卒ご理解いただきますよう、よろしくお願いいたします。`
      }, {
        question: '2022/4/6：どさんこワイド様に取材していただきました',
        answer: 'モッテッテをテレビ番組の「どさんこワイド」様に取材していただきました。'
      }, {
        question: '2022/3/28：今日ドキッ！様に取材していただきました',
        answer: 'モッテッテをテレビ番組の「今日ドキッ！」様に取材していただきました。'
      }]
    }
  }
}
</script>