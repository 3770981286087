<template>
  <div>
    <HeaderCarousels />
    <Top />
    <Title />
    <Description />
    <Appeal />
    <Step />
    <Price />
    <Request />
    <Testimonial />
    <CallToAction />
    <Faq />
    <Youtube />
    <News />
    <Footer />
    <FloatingRequestButton />
  </div>
</template>

<script>
import HeaderCarousels from '@/components/component/landing/HeaderCarousels'
import Top from '@/components/component/landing/Top'
import Title from '@/components/component/landing/Title'
import Description from '@/components/component/landing/Description'
import Appeal from '@/components/component/landing/Appeal'
import Step from '@/components/component/landing/Step'
import Price from '@/components/component/landing/Price'
import Request from '@/components/component/landing/Request'
import Testimonial from '../component/landing/Testimonial.vue'
import CallToAction from '@/components/component/landing/CallToAction'
import Footer from '@/components/component/landing/Footer'
import FloatingRequestButton from '@/components/component/landing/FloatingRequestButton'
import Youtube from '@/components/component/landing/Youtube'
import Faq from '@/components/component/landing/Faq'
import News from '@/components/component/landing/News'

export default {
  components: {
      HeaderCarousels,
      Top,
      Title,
      Description,
      Appeal,
      Step,
      Price,
      Request,
      Testimonial,
      CallToAction,
      Youtube,
      Footer,
      FloatingRequestButton,
      Faq,
      News
  },
}
</script>