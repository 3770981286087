<template>
  <div>
      <v-text-field
      v-model="baggageSpaceNo"
      :rules="baggageSpaceNoRules"
      :counter="20"
      label="宅配ボックスNo"
      :value='baggageSpaceNo'
      hint="宅配ボックスNoは申し込み後、取引履歴より登録することも可能です。"
      persistent-hint
      @change='sendData'
      ></v-text-field>
      <v-text-field
      v-model="baggageSpacepass"
      :rules="baggageSpacepassRules"
      :counter="10"
      label="宅配ボックスパスワード"
      required
      :value='baggageSpacepass'
      @change='sendData'
      ></v-text-field>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'baggageSpacepassProp',
    event: 'inputField1'
  },
  props: {
    baggageSpacepassProp: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      valid: true,
      baggageSpacepass: this.baggageSpacepassProp,
      baggageSpaceNo: null,
      baggageSpacepassRules: [
        v => !!v || 'パスワードは必須入力です。',
        v => Number.isInteger(Number(v)) || 'パスワードは数字だけ可能です',
        v => (v && v.length <= 10) || 'パスワードは10桁迄で入力してください。'
      ],
      baggageSpaceNoRules: [
        v => (!v || (v && v.length <= 20)) || '宅配ボックスNoは20桁迄で入力してください。'
      ]
    }
  },
  methods: {
    sendData: function () {
      this.$emit('inputField1', this.baggageSpaceNo, this.baggageSpacepass)
    }
  },
  watch: {
    baggageSpacepassProp: {
      handler: function (newValue) {
        this.baggageSpacepass = newValue
      }
    }
  }
}
</script>
