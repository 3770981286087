import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
        light: {
          primary: "#00a0e9",
          secondary: "#b2e3f8",
        },
    },
  },
});
