<template>
  <div>
    <v-text-field
    v-model="city"
    :counter="15"
    :rules="cityRules"
    label="市区町村"
    required
    :value='city'
    @change='sendData'
    ></v-text-field>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'cityProp',
    event: 'inputField'
  },
  props: {
    cityProp: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      valid: true,
      city: this.cityProp,
      cityRules: [
        v => !!v || '市区町村は必須入力です。',
        v => (v && v.length <= 10) || '市区町村は15文字以内で入力してください。'
      ]
    }
  },
  methods: {
    sendData: function (e) {
      this.$emit('inputField', e)
    }
  },
  watch: {
    cityProp: {
      handler: function (newValue) {
        this.city = newValue
      }
    }
  }
}
</script>
