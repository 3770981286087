<template>
  <div class="d-flex justify-center">
    <v-stepper v-model="e1" min-height="500" width="450" elevation="0">
      <v-stepper-items>
        <v-stepper-content step="1">
          <p class="mb-5 ml-4">集荷日を設定しましょう</p>
          <Requestday @inputField="setRequestDay"></Requestday>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-icon class="mb-5 ml-2" @click="e1 = 1">mdi-arrow-left</v-icon>
          <p class="mb-5 ml-4">集荷時間を設定しましょう</p>
          <RequestTime @inputField="setRequestTime" />
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-icon class="mb-5 ml-2" @click="e1 = 2">mdi-arrow-left</v-icon>
          <v-form
            ref="form2"
            v-model="valid2"
            lazy-validation
          >
            <InputPostalCode :postalCode.sync='postalCode'></InputPostalCode>
            <div v-if="postalCode.length === 7">
              <InputCity v-model='city'></InputCity>
              <InputAddress1 v-model='address1'></InputAddress1>
              <InputAddress2 v-model='address2'></InputAddress2>
              <div class="mt-4">
                <v-btn x-large color="#64b5f6" block @click="validate3"><span class="font-weight-bold text-h6 white--text">次へ</span></v-btn>
              </div>
            </div>
          </v-form>
        </v-stepper-content>
        <v-stepper-content step="4">
          <v-icon class="mb-5 ml-2" @click="e1 = 3">mdi-arrow-left</v-icon>
          <v-form
          ref="form4"
          v-model="valid3"
          lazy-validation>
            <InputBaggageSpace @inputField="inputBaggageSpace" />
            <div v-if='baggageSpace === "宅配ボックス"'>
              <RequestbaggageSpacepass @inputField1="inputField1"></RequestbaggageSpacepass>
              <div class="mt-4">
                <v-btn x-large color="#64b5f6" block @click="inputBaggageSpaceNoPass"><span class="font-weight-bold text-h6 white--text">次へ</span></v-btn>
              </div>
            </div>
          </v-form>
        </v-stepper-content>
        <v-stepper-content step="5">
          <v-icon class="mb-5 ml-2" @click="e1 = 4">mdi-arrow-left</v-icon>
          <p class="mb-5 ml-4">これで最後です</p>
          <v-form
          ref="form4"
          v-model="valid3"
          lazy-validation>
            <InputName v-model='name'></InputName>
            <InputEmail v-model='email'></InputEmail>
            <InputPassword v-model='password'></InputPassword>
            <InputTel v-model='tel'></InputTel>

            <div class="mt-4">
              <v-btn x-large color="#64b5f6" block @click="validate4"><span class="font-weight-bold text-h6 white--text">次へ</span></v-btn>
            </div>
          </v-form>
        </v-stepper-content>
        <v-stepper-content step="6">
          <v-icon class="mb-5 ml-2" @click="e1 = 5">mdi-arrow-left</v-icon>
          <v-simple-table class="table">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">項目名</th>
                  <th class="text-center">入力</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>氏名</td>
                  <td>{{ name }}</td>
                </tr>
                <tr>
                  <td>メールアドレス</td>
                  <td>{{ email }}</td>
                </tr>
                <tr>
                  <td>パスワード</td>
                  <td>{{ getPasswordView(password) }}</td>
                </tr>
                <tr>
                  <td>TEL</td>
                  <td>{{ tel }}</td>
                </tr>
                <tr>
                  <td>郵便番号</td>
                  <td>{{ postalCode }}</td>
                </tr>
                <tr>
                  <td>TEL</td>
                  <td>{{ tel }}</td>
                </tr>
                <tr>
                  <td>集荷日</td>
                  <td>{{ NewpreferredDate }}</td>
                </tr>
                <tr>
                  <td>集荷時間</td>
                  <td>{{ requestTime }}</td>
                </tr>
                <tr>
                  <td>納品予定日</td>
                  <td>{{ NewdeliveryDate }}</td>
                </tr>
                <tr>
                  <td>置き場</td>
                  <td>{{ baggageSpace }}</td>
                </tr>
                <tr v-if="baggageSpace === '宅配ボックス'">
                  <td v-if="baggageSpace === '宅配ボックス'">宅配ボックスNo</td>
                  <td v-if="baggageSpace === '宅配ボックス'">{{ baggageSpaceNo }}</td>
                </tr>
                <tr v-if="baggageSpace === '宅配ボックス'">
                  <td v-if="baggageSpace === '宅配ボックス'">宅配ボックスパスワード</td>
                  <td v-if="baggageSpace === '宅配ボックス'">{{ baggageSpacepass }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div class="mt-4">
            <v-btn x-large color="#64b5f6" block @click="completed"><span class="font-weight-bold text-h6 white--text">確定</span></v-btn>
          </div>
          <LoadingDialog v-model='loadingDialog'></LoadingDialog>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

import Requestday from '@/components/basic/request/Requestday.vue'
import RequestTime from '@/components/basic/request/RequestTime.vue'
import InputBaggageSpace  from '@/components/basic/user/InputBaggageSpace.vue'
import RequestbaggageSpacepass from '@/components/basic/request/RequestbaggageSpacepass.vue'
import InputPostalCode from '@/components/basic/user/InputPostalCode.vue'
import InputCity from '@/components/basic/user/InputCity.vue'
import InputAddress1 from '@/components/basic/user/InputAddress1.vue'
import InputAddress2 from '@/components/basic/user/InputAddress2.vue'
import InputName from '@/components/basic/user/InputName.vue'
import InputEmail from '@/components/basic/user/InputEmail.vue'
import InputPassword from '@/components/basic/user/InputPassword.vue'
import InputTel from '@/components/basic/user/InputTel.vue'
import LoadingDialog from '@/components/basic/LoadingDialog.vue'

export default {
  components: {
    Requestday,
    RequestTime,
    RequestbaggageSpacepass,
    LoadingDialog,
    InputPostalCode,
    InputCity,
    InputAddress1,
    InputAddress2,
    InputName,
    InputEmail,
    InputPassword,
    InputTel,
    InputBaggageSpace
  },
  data () {
    return {
      // stepper
      valid1: false,
      valid2: false,
      valid3: false,
      db: null,
      db2: null,
      db3: null,
      e1: 1,
      // data
      piker: '',
      memo: '',
      baggageSpace: '',
      name: '',
      kanaName: '',
      address: '',
      tel: '',
      acceptedTerms: false,
      amount: '',
      NewpreferredDate: '',
      NewdeliveryDate: '',
      baggageSpaceNo: '',
      baggageSpacepass: '',
      requestTime: '指定なし',
      koseishaCustomerCode: '',
      koseishaEmployeeCode: '',
      postalCode: '',
      gender: '',
      birthday: '',
      city: '',
      address1: '',
      address2: '',
      email: '',
      password: '',
      // dialog
      loadingDialog: false,
      items: [
        '玄関フード',
        '物置',
        '車庫',
        '宅配ボックス',
        'オートロック付きマンション'
      ]
    }
  },
  created: function () {
    this.db = firebase.firestore()
    this.db2 = firebase.auth()
    this.db3 = firebase.storage()
  },
  methods: {
    getPasswordView: function (password) {
      let str = ''
      for (let i = 0; i < password.length; i++) {
        str = str + '*'
      }
      return str
    },
    inputBaggageSpace: function (e) {
      this.baggageSpace = e
      if (this.baggageSpace !== '宅配ボックス') {
        // 宅配ボックス用データを初期化
        this.baggageSpacepass = ''
        this.baggageSpaceNo = ''
        // 次の画面へ
        this.e1 = 5
      }
    },
    inputBaggageSpaceNoPass: function () {
      if (this.baggageSpacepass) {
        this.e1 = 5
      } else {
        alert('宅配ボックスパスワードを入力してください。')
      }
    },
    validate1: function () {
      if (this.$refs.form1.validate()) {
        this.e1 = 2
      }
    },
    setRequestTime: function (e) {
        this.requestTime = e
        this.e1 = 3
    },
    validate3: function () {
      if (this.$refs.form2.validate()) {
        this.e1 = 4
      }
    },
    validate4: function () {
      if (this.$refs.form4.validate()) {
        this.e1 = 6
      }
    },
    setRequestDay (...dates) {
      const [preferredDate, deliveryDate] = dates

      this.NewpreferredDate = preferredDate
      this.NewdeliveryDate = deliveryDate
      this.e1 = 2
    },
    inputField1 (...numbers) {
      const [baggageSpaceNo, baggageSpacepass] = numbers

      this.baggageSpaceNo = !baggageSpaceNo ? '設置後に登録' : baggageSpaceNo
      this.baggageSpacepass = baggageSpacepass
    },
    completed: async function () {
      this.loadingDialog = true
      try {
        if (!this.password || !this.email) {
          throw new Error('データの読み込みに失敗しました。再度画面を読み込んで下さい。')
        }
        //
        await firebase.auth().createUserWithEmailAndPassword(this.email, this.password)
          .then(async (userCredential) => {
            // tag送信
            if (process.env.NODE_ENV === 'production') {
              const dataLayer = window.dataLayer || []
              dataLayer.push({ event: 'create-user' })
            }
            const user = userCredential.user
            await firebase.firestore().collection('users').doc(user.uid).set({
              uid: user.uid,
              name: this.name,
              kanaName: this.kanaName,
              gender: this.gender,
              birthday: this.birthday,
              email: this.email,
              tel: this.tel,
              postalCode: this.postalCode,
              city: this.city,
              address1: this.address1,
              address2: this.address2,
              baggageSpace: this.baggageSpace,
              koseishaCustomerCode: '0',
              koseishaEmployeeCode: '0',
              updateDate: firebase.firestore.FieldValue.serverTimestamp()
            }, { merge: true })

            await firebase.firestore().collection('requests').add({
              name: this.name,
              kanaName: this.kanaName,
              address: this.city + this.address1 + this.address2,
              preferredDate: this.NewpreferredDate,
              preferredTime: this.requestTime,
              deliveryDate: this.NewdeliveryDate,
              deliveryDateTimeChangeTimes: 0,
              baggageSpace: this.baggageSpace,
              baggageSpaceNo: this.baggageSpaceNo,
              baggageSpacepass: this.baggageSpacepass,
              memo: this.memo,
              tel: this.tel,
              email: this.email,
              uid: user.uid,
              amount: this.amount,
              status: '申し込み',
              koseishaCustomerCode: '0',
              koseishaEmployeeCode: '0',
              endflag: false,
              paymentUrl: '',
              createDate: firebase.firestore.FieldValue.serverTimestamp()
            })
              .then(async () => {
                // tag送信
                if (process.env.NODE_ENV === 'production') {
                  const dataLayer = window.dataLayer || []
                  dataLayer.push({ event: 'create-request' })
                }
              })
              .catch(error => {
                console.error(error)
                throw new Error(error)
              })
          })
          .catch((error) => {
            // 既にユーザーがある時のメッセージのハンドリングを追加したい
            console.error(error.code, error.message)
            console.error(error.code)
            if (error.code === 'auth/email-already-in-use') {
              throw new Error('メールアドレスは既に別のアカウントで使用されています。')
            } else {
              throw new Error('認証エラーが発生しました。')
            }
          })
        // サンクスページへ遷移
        window.location.pathname = '/n/complete'
      } catch (error) {
        alert(error)
        this.e1 = 1
      } finally {
        this.loadingDialog = false
      }
    }
  },
  watch: {
    postalCode: {
      handler: function (newValue) {
        if (process.env.NODE_ENV === 'production') {
          const url = 'https://apis.postcode-jp.com/api/v4/postcodes/' + newValue
          var self = this
          fetch(url, {
            headers: {
              apikey: 'g9xq4YthbH8ZPZqMwdwdNSi2yUIsyUutWWx17EJ'
            }
          }).then(function (response) {
            return response.text()
          }).then(function (text) {
            const postalCodeData = JSON.parse(text.slice(1, -1))
            if (self.city === '') {
              self.city = postalCodeData.city
            }
            if (self.address1 === '') {
              self.address1 = postalCodeData.town
            }
          }).catch(function (error) {
            console.error('郵便番号の取得に失敗しました。', newValue)
            console.error(error)
          })
        }
      }
    }
  }
}
</script>
