<template>
  <v-sheet>
    <v-container class="py-4 py-lg-8">
      <div class="text-center">
        <SectionLabel>
          よくある質問
        </SectionLabel>
      </div>

      <v-expansion-panels
        v-model="panel"
        multiple
        class="mt-2"
      >
        <v-expansion-panel
          v-for="(item, i) in faq"
          :key="i"
        >
          <v-expansion-panel-header class="">
            {{ item.question }}
          </v-expansion-panel-header>
          <v-expansion-panel-content class="">
            {{ item.answer }}
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </v-sheet>
</template>

<script>
import SectionLabel from '@/components/basic/landing/SectionLabel'

export default {
  components: {
    SectionLabel
  },
  data() {
    return {
      panel: [0],
      faq: [{
        question: 'クレジットカードで利用出来るブランドは何がありますか',
        answer: 'Visa、Mastercard、American Express、JCB、Diners Club、Discoverのクレジットカードが使用できます。'
      }, {
        question: '集荷希望日の最短はいつですか',
        answer: '午前１０時までの申し込みで当日、集荷に伺います。'
      }, {
        question: '納品はいつされますか',
        answer: '申し込み日から３から７日後に、集荷した場所に納品されます。宅配ロッカーを利用した場合は集荷時と同じ暗証番号が設定されます。'
      }, {
        question: '利用可能地域はどこですか',
        answer: '札幌市、旭川市、苫小牧市、小樽市、石狩市、江別市、恵庭市、千歳市、砂川市、赤平市、岩見沢市、で集荷可能です。'
      }, {
        question: '集荷場所はどこが指定できますか',
        answer: '宅配ボックス、物置、玄関フードを利用できます。ユーザー登録画面で設定してください。'
      }, {
        question: 'クリーニングの値段はいくらですか',
        answer: 'クリーニングの値段表をご確認ください。'
      }, {
        question: '何にクリーニング品を入れて置いておけばいいですか',
        answer: '初回利用時はご自宅のビニール袋等をご利用ください。納品時に専用の集荷袋に梱包してお渡しします。'
      }, {
        question: '仕上がりに不備があった場合',
        answer: 'Webサイトにログインしてメニューより、お問い合わせください。誠意をもって対応させていただきます。'
      }, {
        question: 'どこで洗っていますか',
        answer: '全て弊社直営のクリーニング工場で洗っています。'
      }, {
        question: '布団は集荷可能ですか？',
        answer: '可能です。ビニール袋等に入れて集荷場所に置いてください。料金は料金表を確認して下さい。'
      }]
    }
  }
}
</script>
