<template>
  <div>
    <v-text-field
    id="inputEmail"
    v-model="email"
    :rules="emailRules"
    label="メールアドレス"
    required
    :value='email'
    @input='sendData'
    ></v-text-field>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'emailProp',
    event: 'inputField'
  },
  props: {
    emailProp: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      email: this.emailProp,
      emailRules: [
        v => !!v || 'メースアドレスは必須入力です。',
        v => /.+@.+\..+/.test(v) || 'メールアドレスの形式で入力してください。'
      ]
    }
  },
  methods: {
    sendData: function (e) {
      this.$emit('inputField', e)
    }
  },
  watch: {
    emailProp: {
      handler: function (newValue) {
        this.email = newValue
      }
    }
  }
}
</script>
