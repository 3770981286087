<template>
  <div class="price__content">
    <v-container class="py-4 py-lg-8">
      <v-row>
        <v-col class="d-flex justify-center">
          <SectionLabel>
            料金
          </SectionLabel>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-for="(item, i) in features"
          :key="i"
          cols="12"
          sm="4"
          lg="4"
        >
            <v-responsive max-width="300" class="mx-auto">
              <div class="price__img">
                <v-img class="price__img--1" contain :src="item.image1" max-height="100"></v-img>
                <v-img class="price__img--2" contain :src="item.image2" max-height="100"></v-img>
              </div>
              <div class="text-center ml-6 text-h6 mt-3 price__section"><span class="font-weight-bold price__h6">{{ item.title }}</span></div>
              <div class="mt-5 ml-8 mb-10">
                <ul>
                  <li v-for="(price, i) in item.prices" :key="i">
                    {{ price }}
                  </li>
                </ul>
              </div>
            </v-responsive>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-end pr-3">
        <v-btn href="/n/service/price" rounded small class="grey--text font-weight-bold">詳細</v-btn>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SectionLabel from '@/components/basic/landing/SectionLabel'

export default {
  components: {
    SectionLabel
  },
  data() {
    return {
      features: [{
        icon: 'mdi-account-check-outline',
        title: 'ビジネス',
        image1: 'https://firebasestorage.googleapis.com/v0/b/non-face-asset/o/landing%2F%E3%82%A4%E3%83%A9%E3%82%B9%E3%83%888.png?alt=media&token=3d32ddb9-0b32-47ed-8932-306b0241bc6e',
        image2: 'https://firebasestorage.googleapis.com/v0/b/non-face-asset/o/landing%2F%E3%82%A4%E3%83%A9%E3%82%B9%E3%83%889.png?alt=media&token=916e5a1d-d3fc-412b-a370-5469eeccf8ab',
        prices: [
          'ワイシャツ　　　１８７円',
          'スーツ上下　１，５２９円',
          'ジャケット　　　９４６円',
          'ズボン　　　　　５８３円',
          'ブラウス　　　　５２８円',
          'スカート　　　　５８３円',
          'ネクタイ　　　　５２８円'
        ]
      }, {
        icon: 'mdi-lifebuoy',
        title: '一般',
        image1: 'https://firebasestorage.googleapis.com/v0/b/non-face-asset/o/landing%2F%E3%82%A4%E3%83%A9%E3%82%B9%E3%83%8810.png?alt=media&token=f2114d17-8b52-40e7-ae4d-9ac9b6cc4448',
        image2: 'https://firebasestorage.googleapis.com/v0/b/non-face-asset/o/landing%2F%E3%82%A4%E3%83%A9%E3%82%B9%E3%83%8811.png?alt=media&token=9d8e9739-513e-42c8-b749-74284ece1093',
        prices: [
          'ポロシャツ　　　　５２８円',
          'カジュアルシャツ　５２８円',
          '帽子　　　　　　　６３８円',
          'ズボン　　　　　　５８３円',
          'セーター　　　　　６３８円',
          'パーカー​　　　　　６３８円'
        ]
      }, {
        icon: 'mdi-email-open-multiple-outline',
        title: '',
        image1: 'https://firebasestorage.googleapis.com/v0/b/non-face-asset/o/landing%2F%E3%82%A4%E3%83%A9%E3%82%B9%E3%83%8812.png?alt=media&token=01c0755e-303a-4e4b-8f7b-0fe82b36f5f6',
        image2: 'https://firebasestorage.googleapis.com/v0/b/non-face-asset/o/landing%2F%E3%82%A4%E3%83%A9%E3%82%B9%E3%83%8813.png?alt=media&token=c114d0e7-5131-43db-8197-c29b39545495',
        prices: [
          'ジャケット　　　　９４６円​',
          'ワンピース　　　　９４６円',
          '​タオルケット　　　９４６円'
        ]
      }]
    }
  }
}
</script>

<style lang="scss">
.price {
  &__content {
    // background-color: #b2e3f8;
  }
  &__section {
    position: relative;
    // height: 90px;
  }
  &__h6 {
  }
  &__img {
    margin: auto;
    position: relative;
    height: 180px;
    width: 160px;
    &--1 {
      position: absolute;
      top: 0;
      left: 0;
    }
    &--2 {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}
</style>
