<template>
  <v-sheet :height="elementHeight">
    <v-carousel
      :continuous="true"
      :cycle="cycle"
      interval="4000"
      hide-delimiter-background
      delimiter-icon="mdi-minus"
      height="100%"
    >
      <v-carousel-item
        v-for="(item, i) in items"
        :key="i"
        :src="item"
      >
      </v-carousel-item>
    </v-carousel>
  </v-sheet>
</template>

<script>
  export default {
    data () {
      return {
        items: [
          {
            src: 'https://firebasestorage.googleapis.com/v0/b/non-face-asset/o/landing%2F%E3%83%A2%E3%83%83%E3%83%86%E3%83%83%E3%83%86%E3%82%BB%E3%82%AD%E3%83%A5%E3%83%AA%E3%83%86%E3%82%A3%E3%83%90%E3%83%8A%E3%83%BC.jpg?alt=media&token=3da530f1-bb7f-46c6-84f6-746763c8bf9f',
          },
          {
            src: 'https://firebasestorage.googleapis.com/v0/b/non-face-asset/o/landing%2F%E5%88%9D%E5%9B%9E%E5%8D%8A%E9%A1%8D2.jpg?alt=media&token=2553450d-2b3f-48c7-b445-a15c32c41042',
          }
        ],
        cycle: true,
        elementHeight: 0
      }
    },
    created: function () {
      this.calcHeight()
      // ウィンドウ幅が変えられた時に確保した高さを動的に変更
      window.onresize = () => { this.calcHeight() }
    },
    methods: {
      calcHeight: function () {
        // ウィンドウの横幅からカルーセルの高さを確保
        this.elementHeight = (window.innerWidth / 1280) * 720
      }
    }
  }
</script>