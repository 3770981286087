<template>
  <div class="testimonial__content">
    <v-container class="py-4 py-lg-8">
      <v-row>
        <v-col class="d-flex justify-center">
          <SectionLabel>
            お客様の声
          </SectionLabel>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-for="(item, i) in features"
          :key="i"
          cols="6"
          sm="4"
          lg="4"
          class="testimonial__col"
        >
            <div max-width="300" class="mx-auto text-center testimonial__comment">
              <div class="font-weight-regular mt-2">{{ item.description }}</div>
            </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SectionLabel from '@/components/basic/landing/SectionLabel'

export default {
  components: {
    SectionLabel
  },
  data() {
    return {
      features: [{
        description: 'Yシャツで利用しているシワなくキレイに納品されてよかった。'
      }, {
        description: '​犬の散歩に行ったり、細かく出かけることが多いので置き配は便利だと思います。'
      }, {
        description: '​持病で体調が悪い日もあるので対面応対でないのがありがたいです。'
      }, {
        description: 'コロナ禍で利用したが便利でした。'
      }, {
        description: '​仕事で不在がちなのでとてもありがたいです。'
      }, {
        description: '​回収日時に家で待たなければならないという時間のロスをなくすことができた。'
      }]
    }
  }
}
</script>

<style lang="scss">
.testimonial {
  &__content {
    background-color: #ccecfb;
  }
  &__comment {
    position: relative;
    background-color: white;
    z-index: 1;
    padding: 25px;
    margin-bottom: 20px;
    border-radius: 35px;
    &:before {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      z-index: -1;
      border-top: 20px solid white;
      border-right: 20px solid transparent;
      border-left: 20px solid transparent;
    }
  }
}
</style>
