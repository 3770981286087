<template>
  <div class="step__content">
    <v-container class="py-4 py-lg-8">
      <v-row>
        <v-col class="d-flex justify-center">
          <SectionLabel>
            利用手順
          </SectionLabel>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-for="(item, i) in features"
          :key="i"
          cols="6"
          sm="6"
          lg="6"
        >
            <v-responsive max-width="300" class="mx-auto">
              <div class="ml-6 text-h6 mt-3 step__section"><span class="step__no primary--text text-h3">{{ item.no }}</span><span class="primary--text font-weight-bold step__h6">{{ item.title }}</span></div>
              <div class="step__img d-flex justify-center">
                <v-img :src="item.image" max-width="100"></v-img>
              </div>
              <div class="ml-4 font-weight-regular mt-2">{{ item.description }}</div>
            </v-responsive>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SectionLabel from '@/components/basic/landing/SectionLabel'

export default {
  components: {
    SectionLabel
  },
  data() {
    return {
      features: [{
        icon: 'mdi-account-check-outline',
        no: '1',
        title: 'サイトで​集荷申し込み',
        description: '専用Webサイトで、集荷希望日、置き場所を入力してください。置き場所は玄関フード、物置、車庫、宅配ボックスから選ぶことができます。',
        image: 'https://firebasestorage.googleapis.com/v0/b/non-face-asset/o/landing%2F%E3%82%A4%E3%83%A9%E3%82%B9%E3%83%885.png?alt=media&token=54f7c080-3dbb-4864-bae3-b7045616a2b0'
      }, {
        icon: 'mdi-lifebuoy',
        no: '2',
        title: '指定日の朝に指定の場所へ',
        description: '当日の朝、指定の場所にダンボール、紙袋等に入れて、置いておいてください。その後は家にいる必要はありません。２回目以降は専用バックに入れて出すことが可能です。',
        image: 'https://firebasestorage.googleapis.com/v0/b/non-face-asset/o/landing%2F%E3%82%A4%E3%83%A9%E3%82%B9%E3%83%886.png?alt=media&token=30bf2115-3171-4dbd-8f6c-ea4a5d7b1285'
      }, {
        icon: 'mdi-email-open-multiple-outline',
        no: '3',
        title: 'お支払い',
        description: '集荷後に料金が確定します。Webサイト上でお支払いいただきます。クレジットカード、PayPay、LINEPay、キャリア決済(Docomo、au、Softbank)が利用できます。',
        image: 'https://firebasestorage.googleapis.com/v0/b/non-face-asset/o/landing%2F%E3%82%A4%E3%83%A9%E3%82%B9%E3%83%88.png?alt=media&token=3c1d31b5-eb56-4ea5-b78e-048deff54a3a'
      }, {
        icon: 'mdi-clock-outline',
        no: '4',
        title: '納品日​確定',
        description: '納品日が決まり次第、通知されます。Webサイトで確認できます。ご都合が悪い場合は変更できます。',
        image: 'https://firebasestorage.googleapis.com/v0/b/non-face-asset/o/landing%2F%E3%82%A4%E3%83%A9%E3%82%B9%E3%83%887.png?alt=media&token=42d83c52-3475-4889-93c6-da980dec0042'
      }]
    }
  }
}
</script>

<style lang="scss">
.step {
  &__content {
    background-color: #b2e3f8;
  }
  &__section {
    position: relative;
    height: 90px;
  }
  &__h6 {
    // background-color: white;
    position: relative;
    z-index: 1;
  }
  &__no {
    position: relative;
    z-index: 1;
    &:before {
      content: "";
      position: absolute;
      background: white;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      top: 50%;
      transform: translateY(-50%);
      left: -20px;
      z-index: -1;
    }
  }
  &__img {
  }
}
</style>
