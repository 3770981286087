<template>
  <div class="d-flex justify-center mb-3">
    <v-date-picker
      v-model="picker"
      :min="minDate"
      :max="maxDate"
      :allowed-dates="allowedDates"
      @change='sendData'
      locale="ja"
      elevation="3"
      class=""
    ></v-date-picker>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/firestore'

import dayjs from 'dayjs'

export default {
  model: {
    prop: 'requestdayProp',
    event: 'inputField'
  },
  props: {
    pickerProp: {
      type: String,
      default: null
    }
  },
  created: function () {
    var _this = this
    firebase.firestore().collection('holidays')
      .onSnapshot(function (querySnapshot) {
        _this.list = []
        querySnapshot.forEach(function (doc) {
          const data = doc.data()
          data.id = doc.id
          _this.list.push(data.holiday)
        })
      })
  },
  data () {
    return {
      picker: null,
      list: [],
      deliveryDateA: '',
      deliveryDateB: '',
      deliveryDate: ''
    }
  },
  computed: {
    minDate () {
      const oclock = dayjs().format('HH')
      var minDay

      // 10時以降は翌日
      if (oclock >= 10) {
        minDay = dayjs().add(1, 'day').format('YYYY-MM-DD')
      } else {
        minDay = dayjs().format('YYYY-MM-DD')
      }
      return minDay
    },
    maxDate () {
      // 二か月後
      const today = new Date()
      const max = new Date(today)
      max.setDate(max.getDate() + 60)
      let dateVal = max.getFullYear()
      const month = max.getMonth() + 1
      dateVal += '-' + ((month >= 10) ? month : '0' + month)
      const date = max.getDate()
      dateVal += '-' + ((date >= 10) ? date : '0' + date)
      return dateVal
    }
  },
  methods: {
    sendData: function (e) {
      this.deliveryDateA = dayjs(e).add(5, 'day').format('YYYY-MM-DD')
      this.deliveryDateB = dayjs(e).add(11, 'day').format('YYYY-MM-DD')

      this.deliveryDate = this.deliveryDateA + '  ～  ' + this.deliveryDateB

      this.$emit('inputField', e, this.deliveryDate)
    },
    allowedDates: function allowedDates (val) {
      if (this.list.some(date => date === val)) {
        return false
      } else {
        return true
      }
    }
  }
}
</script>
