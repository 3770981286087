<template>
  <div>
      <v-text-field
      v-model="address1"
      :rules="address1Rules"
      :counter="30"
      label="住所詳細"
      required
      :value='address1'
      @change='sendData'
      ></v-text-field>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'address1Prop',
    event: 'inputField'
  },
  props: {
    address1Prop: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      valid: true,
      address1: this.address1Prop,
      address1Rules: [
        v => !!v || '住所詳細は必須入力です。',
        v => (v && v.length <= 30) || '住所詳細は30文字以内で入力してください。'
      ]
    }
  },
  methods: {
    sendData: function (e) {
      this.$emit('inputField', e)
    }
  },
  watch: {
    address1Prop: {
      handler: function (newValue) {
        this.address1 = newValue
      }
    }
  }
}
</script>
