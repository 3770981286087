<template>
  <div>
    <v-dialog
      v-model="loadingDialog"
      persistent
      width="300"
    >
      <v-card
      color="primary"
      dark
      >
        <v-card-text>
          しばらくお待ちください。
          <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'loadingDialogProp'
  },
  props: {
    loadingDialogProp: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loadingDialog: this.loadingDialogProp
    }
  },
  watch: {
    loadingDialogProp: {
      handler: function (newValue) {
        this.loadingDialog = newValue
      }
    },
    loadingDialog (val) {
      if (!val) return

      setTimeout(() => (this.loadingDialog = false), 60000)
    }
  }
}
</script>
