<template>
  <v-sheet color="transparent">
    <v-container class="pt-0 text-center">
      <h3 class="">持っていく煩わしさも洗い流す</h3>
      <v-img class="title__img" src="https://firebasestorage.googleapis.com/v0/b/non-face-asset/o/landing%2F%E3%83%A2%E3%83%83%E3%83%86%E3%83%83%E3%83%86%E3%83%AD%E3%82%B4_%E6%96%87%E6%9C%89%E3%82%8A.png?alt=media&token=52ecdf86-af2a-4517-b525-ff65d164aeb5" max-width="600"></v-img>
      <h3 class="primary--text">置き配クリーニング 受付中</h3>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  components: {
  }
}
</script>

<style lang="scss">
.title {
  &__img {
    margin: auto;
  }
}
</style>